import { HttpParams } from '@angular/common/http';
import { TreeviewItem } from 'ngx-treeview';

export class CommonUtility {

    static datatable: any = null;

    static delay = ms => new Promise(res => setTimeout(res, ms));

    static isNull(item): boolean {
        return item === undefined || item === null;
    }

    static isEmpty(item): boolean {
        return item === undefined || item === null
            || item.length === 0 || item === 0 || item === '' || item === 'null';
    }

    static isNotNull(item): boolean {
        return item !== undefined && item !== null;
    }

    static isNotEmpty(item): boolean {
        return item !== undefined && item !== null && item.length !== 0;
    }

    static isObjectEmpty(obj): boolean {
        return CommonUtility.isNull(obj) || Object.keys(obj).length === 0 || !Object.keys(obj).some(k => CommonUtility.isNotEmpty(obj[k]));
    }

    static splitKeys(keys: string, separator: string = ','): string[] {
        return keys.split(separator);
    }

    static convertObjectToParams(paramObj: object) {
        let params = new URLSearchParams();
        for (let key in (paramObj || {})) {
            if (paramObj.hasOwnProperty(key) && CommonUtility.isNotEmpty(paramObj[key]) && typeof (paramObj[key]) !== 'object') {
                params.set(key, paramObj[key])
            } else if (Array.isArray(paramObj[key])) {
                paramObj[key].forEach(k => {
                    params.append(key, k);
                })
            }
        }
        return params;
    }

    // convert array to url search params
    static convertArrayToParams(paramArray: any[]) {
        let params = new HttpParams();
        paramArray.forEach(element => {
            params = params.append(element.key, element.value);
        });
        return params;
    }

    static convertObjectToUrlEncoded(paramObj: object) {
        let params = new HttpParams();
        for (let key in paramObj) {
            if (paramObj.hasOwnProperty(key) && paramObj[key]) {
                params = params.append(key, paramObj[key])
            }
        }
        return params;
    }

    static convertToUrlParams = (object) => {
        var parameters = [];
        for (var property in object) {
            if (object.hasOwnProperty(property)) {
                parameters.push(encodeURI(property + '=' + object[property]));
            }
        }

        return parameters.join('&');
    }

    static getParameter(paramName) {
        var searchString = window.location.search.substring(1),
            i, val, params = searchString.split("&");

        for (i = 0; i < params.length; i++) {
            val = params[i].split("=");
            if (val[0] == paramName) {
                return val[1];
            }
        }
        return null;
    }


    static isNumber(n): boolean {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }


    static findItem(root: TreeviewItem, value: any): TreeviewItem {
        if (CommonUtility.isObjectEmpty(root)) {
            return undefined;
        }

        if (root.value.value === value) {
            return root;
        }

        if (root.children) {
            for (const child of root.children) {
                const foundItem = CommonUtility.findItem(child, value);
                if (foundItem) {
                    return foundItem;
                }
            }
        }

        return undefined;
    }

    static findItemInList(list: TreeviewItem[], value: any): TreeviewItem {
        if (CommonUtility.isObjectEmpty(list)) {
            return undefined;
        }

        for (const item of list) {
            const foundItem = CommonUtility.findItem(item, value);
            if (foundItem) {
                return foundItem;
            }
        }

        return undefined;
    }

    static findCategoryInList(list: TreeviewItem[], value: any): TreeviewItem {
        if (CommonUtility.isObjectEmpty(list)) {
            return undefined;
        }

        for (const item of list) {
            const foundItem = CommonUtility.findCategory(item, value);
            if (foundItem) {
                return foundItem;
            }
        }

        return undefined;
    }

    static findCategory(root: TreeviewItem, value: any): TreeviewItem {
        if (CommonUtility.isObjectEmpty(root)) {
            return undefined;
        }

        if (root.value.id === value) {
            return root;
        }

        if (root.children) {
            for (const child of root.children) {
                const foundItem = CommonUtility.findCategory(child, value);
                if (foundItem) {
                    return foundItem;
                }
            }
        }

        return undefined;
    }

    static findCategoryValueInList(list: TreeviewItem[], value: any): TreeviewItem {
        if (CommonUtility.isObjectEmpty(list)) {
            return undefined;
        }

        const foundItem = list.filter(ele => ele.text === value);
        if (foundItem && foundItem.length > 0) {
            return foundItem[0];
        }

        return undefined;
    }

    static exportExcelDataGenerator(studentData: any[], columnMetaInfo: any[]): any[] {
        return studentData.map((x, index) => {
            const temp = {
                "S.No": index + 1
            };
            for (let column of columnMetaInfo) {
                if (column && column.isVisible) {
                    if (column.columnName.indexOf(".") !== -1) {
                        const splited = column.columnName.split('.');
                        if (splited.length === 2) {
                            temp[column.displayName] = splited != null && x[splited[0]] != null && x[splited[0]][splited[1]] != null ? x[splited[0]][splited[1]] : null
                        } else if (splited.length === 3) {
                            temp[column.displayName] = splited != null && x[splited[0]] != null && x[splited[0]][splited[1]] != null && x[splited[0]][splited[1]][splited[2]] != null ? x[splited[0]][splited[1]][splited[2]] : null
                        } else if (splited.length === 4) {
                            temp[column.displayName] = splited != null && x[splited[0]] != null && x[splited[0]][splited[1]] != null && x[splited[0]][splited[1]][splited[2]] != null && x[splited[0]][splited[1]][splited[2]][splited[3]] != null ? x[splited[0]][splited[1]][splited[2]][splited[3]] : null
                        }
                    } else {
                        temp[column.displayName] = x[column.columnName]
                    }
                }
            }
            return temp;
        });
    }

    focusOnLabel=(identifier)=>{document.getElementById(identifier).focus()}
}